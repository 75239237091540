.table-container {
    max-width: 800px; /* Set a max width for larger screens */
    margin: 0 auto; /* Center the container */
}

table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Collapse borders */
}

th, td {
    border: 1px solid #dee2e6; /* Border for table cells */
    padding: 10px; /* Padding for cells */
    text-align: center; /* Center text in cells */
}

th {
    background-color: #573D2A; /* Header background color */
    font-weight: bold; /* Bold header text */
    color: #fff;
}

.overlay {
    position: fixed; /* Overlay styles */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex; /* Center spinner */
    justify-content: center;
    align-items: center;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #fff; /* Spinner color */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
